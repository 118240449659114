import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"

interface Props {
  readonly data: PageQueryData
}

const CookiesAndSubprocessors: React.FC<Props> = ({data}) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title="Tercen" keywords={[`tercen`, `data science platform`]}>
      <section className="w-full">
        <div className="container px-6 py-12">
          <h1 className=" font-bold text-3xl">Tercen Subprocessors and Cookies</h1>
        </div>
      </section>
      <section className="container py-6 px-6 md:px-40 text-sm">
        <p className="py-6">In this article:</p>
        <ul className="list-disc">
          <li>Tercen Subprocessors</li>
          <li>Cookies on Tercen</li>
          <li>Tracking on Tercen</li>
        </ul>
        <p className="py-6">Effective date: May 19, 2020</p>
        <p className="py-6">
          Tercen provides a great deal of transparency regarding how we use your data, how we collect your data, and
          with whom we share your data. To that end, we provide this page, which details our subprocessors, how we use
          cookies, and where and how we perform any tracking on Tercen.
        </p>
        <h2 className="py-6 font-medium text-3xl">Tercen Subprocessors</h2>
        <p className="py-6">
          When we share your information with third party sub-processors, such as our vendors and service providers, we
          remain responsible for it. We work very hard to maintain your trust when we bring on new vendors, and we
          assess their data protection agreements that restrict their processing of Users’ Personal Information (as
          defined in the Privacy Statement).
        </p>
        <table className="table-auto w-1/2 mx-auto">
          <thead>
            <tr>
              <th>Name of Subprocessor</th>
              <th>Description of Processing</th>
              <th>Location of Processing</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Braintree (PayPal)</td>
              <td>Subscription credit card payment processor</td>
              <td>United States</td>
            </tr>
            <tr>
              <td>Google Analytics</td>
              <td>Website analytics and performance</td>
              <td>United States</td>
            </tr>
            <tr>
              <td>Copper.com</td>
              <td>Customer relations management</td>
              <td>United States</td>
            </tr>
            <tr>
              <td>Stripe</td>
              <td>Payment provider</td>
              <td>Ireland</td>
            </tr>
            <tr>
              <td>Heap</td>
              <td>Website Analytics</td>
              <td>United States</td>
            </tr>
          </tbody>
        </table>
        <p className="py-6">
          When we bring on a new subprocessor who handles our Users’ Personal Information, or remove a subprocessor, or
          we change how we use a subprocessor, we will update this page. If you have questions or concerns about a new
          subprocessor, we’d be happy to help.
        </p>
        <h2 className="py-6 font-medium text-3xl">Cookies on Tercen</h2>
        <p className="py-6">
          Tercen uses cookies to make interactions with our service easy and meaningful. We use cookies (and similar
          technologies, like HTML5 localStorage) to keep you logged in, remember your preferences, provide information
          for future development of Tercen, and to advertise Tercen’s products and services to you on third party sites.
        </p>
        <p className="py-6">
          A cookie is a small piece of text that our web server stores on your computer or mobile device, which your
          browser sends to us when you return to our site. Cookies do not necessarily identify you if you are merely
          visiting Tercen; however, a cookie may store a unique identifier for each logged in user. We use cookies to
          keep you logged in, remember your preferences, and provide information for future development of Tercen. For
          security reasons, we use cookies to identify a device. By using our website, you agree that we can place these
          types of cookies on your computer or device. If you disable your browser or device’s ability to accept these
          cookies, you will not be able to log in or use Tercen’s services. On certain areas of the website, we may also
          use cookies to identify you and/or your device to advertise Tercen products and services to you on third party
          sites.
        </p>
        <p className="py-6">Tercen sets the following cookies on our users for the following reasons:</p>
        <table className="table-auto w-1/2 mx-auto">
          <thead>
            <tr>
              <th>Name of Cookie/ Local storage</th>
              <th>Reason</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>authorization</td>
              <td>used to log you in.</td>
            </tr>
            <tr>
              <td>_ga</td>
              <td>This cookie is used by Google Analytics.</td>
            </tr>
            <tr>
              <td>_gat</td>
              <td>This cookie is used by Google Analytics.</td>
            </tr>
            <tr>
              <td>_gid</td>
              <td>This cookie is used by Google Analytics.</td>
            </tr>
          </tbody>
        </table>
        <p className="py-6">
          Certain pages on our site may set other third party cookies. For example, we may embed content, such as
          videos, from another site that sets a cookie. While we try to minimize these third party cookies, we can’t
          always control what cookies this third party content sets.
        </p>
        <h2 className="py-6 font-medium text-3xl">Tracking on Tercen</h2>
        <p className="py-6">
          “
          <a
            href="https://www.eff.org/issues/do-not-track"
            target="_blank"
            className="text-tercen-blue hover:text-tercen-blue-hover">
            Do Not Track
          </a>
          ” (DNT) is a privacy preference you can set in your browser if you do not want online services — specifically
          ad networks — to collect and share certain kinds of information about your online activity from third party
          tracking services. Tercen responds to browser DNT signals and follows the{" "}
          <a
            href="https://www.w3.org/TR/tracking-dnt/"
            target="_blank"
            className="text-tercen-blue hover:text-tercen-blue-hover">
            W3C standard for responding to DNT signals
          </a>
          . If you would like to set your browser to signal that you would not like to be tracked, please check your
          browser’s documentation for how to enable that signal. There are also good applications that block online
          tracking, such as{" "}
          <a
            href="https://www.eff.org/privacybadger"
            target="_blank"
            className="text-tercen-blue hover:text-tercen-blue-hover">
            Privacy Badger.
          </a>
          .
        </p>
        <p className="py-6">
          If you have not enabled DNT on a browser that supports it, cookies on some parts of our website will track
          your online browsing activity on other online services over time, though we do not permit third parties other
          than our analytics and service providers to track Tercen users’ activity over time on Tercen. We use these
          cookies to allow us to advertise Tercen products and services to you on third party websites and services. We
          also have agreements with certain vendors, such as analytics providers, who help us track visitors’ movements
          on certain pages on our site. Only our vendors, who are collecting personal information on our behalf, may
          collect data on our pages, and we have signed data protection agreements with every vendor who collects this
          data on our behalf. We use the data we receive from these vendors to better understand our visitors’
          interests, to understand our website’s performance, and to improve our content. Any analytics vendor will be
          listed in our Subprocessor List above, and you may see a list of every page where we collect this kind of data
          below.
        </p>
        <h2 className="py-6 font-medium text-3xl">Google Analytics</h2>
        <p className="py-6">
          We use Google Analytics as a third party analytics service, and to track our advertising campaigns on third
          party websites and services. We use Google Analytics to collect information about how our website performs and
          how our users, in general, navigate through and use Tercen. This helps us evaluate our users’ use of Tercen;
          compile statistical reports on activity; and improve our content and website performance. Google provides
          further information about its own privacy practices and{" "}
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            target="_blank"
            className="text-tercen-blue hover:text-tercen-blue-hover">
            offers a browser add-on to opt out of Google Analytics tracking
          </a>
          .
        </p>
        <h2 className="py-6 font-medium text-3xl">Pages on Tercen where analytics may be enabled</h2>
        <p className="py-6">
          Pages at URLs that contain any of the following domains and paths (including any subdomains or subpaths) on
          our sites may have analytics or other tracking code enabled. If you would like to prevent us from collecting
          information about your browsing activity on Tercen, you may use a tracking blocker such as{" "}
          <a
            href="https://www.eff.org/privacybadger"
            target="_blank"
            className="text-tercen-blue hover:text-tercen-blue-hover">
            Privacy Badger.
          </a>{" "}
          or opt out of Google Analytics tracking.
        </p>
        <ul className="list-disc">
          <li>tercen.com</li>
          <li>www.tercen.com</li>
        </ul>
      </section>
    </Layout>
  )
}

interface PageQueryData {
  site: {
    siteMetadata: {
      title: string
    }
  }
}

export const pageQuery = graphql`
  query CookiesAndSubprocessorsQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
export default CookiesAndSubprocessors
